body {
    font-family: 'Poppins', sans-serif;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.home, .post, .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin-left: 150px;
}

@media only screen and (max-width: 600px){
	/* body{
	    overflow: hidden;
	} */
    /* *{
        -ms-overflow-style: none;
    } */
    ::-webkit-scrollbar {
        display: none;
    }
    .home, .post, .profile {
        margin-left: 0;
    }
}